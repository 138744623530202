const mobileMenuWrap = document.querySelector(".js-mobile-menu-wrap");
const links = document.querySelectorAll(".js-mobile-menu__link");
const toggleMenuBtn = document.querySelectorAll(".js-toggle-menu");

const linkClickHandler = (e) => {
	const subMenu = e.target.parentElement.querySelector(
		".js-mobile-menu__sub-menu"
	);

	if (subMenu) {
		e.preventDefault();
		subMenu.classList.toggle("visible");
	}
};

const menuToggleHandler = () => mobileMenuWrap.classList.toggle("visible");

links.forEach((item) => item.addEventListener("click", linkClickHandler));
toggleMenuBtn.forEach((btn) =>
	btn.addEventListener("click", menuToggleHandler)
);
