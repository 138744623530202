const updatePriceEstimate = (qtyInput, costInput) => (e) => {
	const qty = new Number(qtyInput.value);

	const price = siteData.product.price * qty;

	// set value rounded to two decimal places
	costInput.value = Math.round((price + Number.EPSILON) * 100) / 100;
};

const adjustInputQty = (input, direction) => (e) => {
	e.preventDefault();

	let qty = new Number(input.value);

	if (direction === "increment") {
		qty += 1;
	}

	if (direction === "decrement" && qty > input.min) {
		qty -= 1;
	}

	input.value = qty;
};

const setup = () => {
	const purchaseSections = document.querySelectorAll(".js-purchase-section");

	purchaseSections.forEach((section) => {
		const btns = section.querySelectorAll(".js-set-product-qty");
		const qtyInput = section.querySelector(".js-product-qty");
		const costInput = section.querySelector(".js-cost-input");

		const fireUpdate = updatePriceEstimate(qtyInput, costInput);

		// run on load to ensure correct price according to default value
		fireUpdate();

		qtyInput.addEventListener("change", (e) => {
			if (e.currentTarget.value < e.currentTarget.min) {
				e.currentTarget.value = e.currentTarget.min;
			}
		});

		btns.forEach((btn) => {
			const direction = btn.dataset.direction;

			if (!direction) return;

			btn.addEventListener("click", adjustInputQty(qtyInput, direction));
			if (qtyInput && costInput) {
				btn.addEventListener("click", fireUpdate);
			}
		});

		if (qtyInput && costInput) {
			qtyInput.addEventListener(
				"input",
				updatePriceEstimate(qtyInput, costInput)
			);
		}
	});
};

window.addEventListener("DOMContentLoaded", () => setup());
