import Swiper, { Autoplay, Pagination, Controller } from "swiper";

Swiper.use([Autoplay, Pagination, Controller]);

const sliderToColor = (slider, color) => {
	let selected = 1;

	slider.slides.each(
		(item, idx) => item.dataset.color === color && (selected = idx)
	);

	slider.slideTo(selected);
};

window.addEventListener("load", function () {
	const defaultOptions = {
		speed: 2000,
		loop: true,
	};

	const sliders = [
		{
			sliderClass: ".js-product-slider",
			slideClass: ".js-product-slide",
			pagNext: ".swiper-button-next",
			pagPrev: ".swiper-button-prev",
			thumbnails: ".js-product-thumbnail",
			options: {
				...defaultOptions,
				noSwiping: true,
				autoHeight: true,
			},
		},
		{
			sliderClass: ".js-content-gallery",
			slideClass: ".js-content-gallery-slide",
			pagNext: ".js-content-gallery-next",
			pagPrev: ".js-content-gallery-prev",
			options: {
				...defaultOptions,
				noSwiping: true,
				autoHeight: true,
			},
		},
		{
			sliderClass: ".js-testimonial-slider",
			slideClass: ".js-testimonial-slide",
			pagNext: ".js-testimonial-slider-next",
			pagPrev: ".js-testimonial-slider-prev",
			options: {
				...defaultOptions,
				autoHeight: true,
				slidesPerView: "auto",
				pagination: {
					el: ".js-testimonial-slider-pagination",
					type: "bullets",
					clickable: true,
				},
			},
		},
	];

	const addSliderWrapper = (sliderEl) => {
		const slideContent = sliderEl.innerHTML;

		sliderEl.innerHTML = "";

		const wrapper = document.createElement("div");
		wrapper.classList.add("swiper-wrapper");

		wrapper.innerHTML = slideContent;

		sliderEl.appendChild(wrapper);
	};

	sliders.forEach(
		({
			sliderClass,
			slideClass,
			pagNext,
			pagPrev,
			options,
			thumbnails,
		}) => {
			const sliderEl = document.querySelector(sliderClass);

			if (!sliderEl) {
				return;
			}

			const slides = sliderEl.querySelectorAll(slideClass);

			const isVariable = sliderEl.classList.contains("js-variable-slide");

			if (slides.length <= 1) return;

			// add required classes
			sliderEl.classList.add("swiper-container");
			slides.forEach((slide) => slide.classList.add("swiper-slide"));
			addSliderWrapper(sliderEl);

			const slider = new Swiper(sliderClass, options);

			if (pagPrev) {
				document
					.querySelector(pagPrev)
					.addEventListener("click", () => {
						slider.slidePrev();
					});
			}

			if (pagNext) {
				document
					.querySelector(pagNext)
					.addEventListener("click", () => slider.slideNext());
			}

			if (thumbnails) {
				const thumbs = document.querySelectorAll(thumbnails);

				slider.on("slideChange", (s) => {
					thumbs.forEach((t) =>
						t.dataset.slideIdx == s.activeIndex
							? t.classList.add("active")
							: t.classList.remove("active")
					);
				});

				thumbs.forEach((thumb) => {
					thumb.addEventListener("click", (e) => {
						slider.slideTo(thumb.dataset.slideIdx);
					});
				});
			}

			if (isVariable) {
				const variableWrapper = document.querySelector(
					".variable-items-wrapper"
				);

				const variableItems =
					variableWrapper.querySelectorAll(".variable-item");

				[...variableItems].forEach((item) =>
					item.addEventListener("click", () => {
						const selectedColor = item.dataset.value;

						sliderToColor(slider, selectedColor);
					})
				);

				const params = new URLSearchParams(window.location.search);
				const color = params.get("attribute_pa_color");

				if (color) {
					sliderToColor(slider, color);
				}
			}
		}
	);
});
