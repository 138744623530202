(function ($) {
	$(document).on("click", ".js-add-to-cart", function (e) {
		e.preventDefault();

		const addToCartBtn = $(this),
			form = addToCartBtn.closest("form.cart"),
			id = addToCartBtn.val(),
			product_qty = form.find("input[name=quantity]").val() || 1,
			product_id = form.find("input[name=product_id]").val() || id,
			variation_id = form.find("input[name=variation_id]").val() || 0;

		const data = {
			action: "woocommerce_ajax_add_to_cart",
			product_id: product_id,
			product_sku: "",
			quantity: product_qty,
			variation_id: variation_id,
		};

		$(document.body).trigger("adding_to_cart", [addToCartBtn, data]);

		$.ajax({
			type: "post",
			url: wc_add_to_cart_params.ajax_url,
			data: data,
			beforeSend: function (response) {
				addToCartBtn.removeClass("added").addClass("loading");
			},
			complete: function (response) {
				addToCartBtn.addClass("added").removeClass("loading");
			},
			success: function (response) {
				if (response.error && response.product_url) {
					window.location = response.product_url;
					return;
				} else {
					$(document.body).trigger("added_to_cart", [
						response.fragments,
						response.cart_hash,
						addToCartBtn,
					]);
				}
			},
		});

		return false;
	});
})(jQuery);
