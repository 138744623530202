jQuery(function ($) {
	const button = $(".js-load-more");

	if (button.data("maxPages") === 1) {
		button.remove();
		return;
	}

	button.click(function (e) {
		e.preventDefault();

		const originalText = button.text();
		const nextPage = button.data("currentPage") + 1;
		const maxPages = button.data("maxPages");

		if (maxPages === 1) {
			button.remove();
			return;
		}

		const data = {
			action: "loadmore",
			postType: button.data("postType"),
			page: nextPage,
			limit: button.data("limit"),
			name: button.data("name"),
			style: button.data("style"),
		};

		button.prop("disabled", true);

		$.ajax({
			url: siteData.ajaxUrl,
			data: data,
			type: "POST",
			beforeSend: function () {
				button.text("Loading...");
			},
			success: function (data) {
				if (!data || nextPage === maxPages) button.remove();

				$(".js-ajax-content").append(data);

				button.prop("disabled", false);
				button.text(originalText);
				button.data("currentPage", nextPage);
			},
		});
	});
});
